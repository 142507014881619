import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import WorkInfo from "../../components/work-info"
import { StaticImage } from "gatsby-plugin-image"
import ProjectCard from "../../components/project-card"
import GoSun from "../../images/projects/gosun/cover--2.jpg"
import Auris from "../../images/projects/auris/cover.jpg"

const aurisPage = () => (
  <Layout>
    <SEO title="JackRabbit Bike" />
    <WorkInfo 
      title="JackRabbit Bike"
      link="jackrabbit.bike"
      team1="Bailey Latimer"
      team2="Michael Budde"
      team3="Jacob Kossman"
      challenge="Making commuting fun again."
      solution="Making commuting fun again."/>

    <StaticImage class="mb-6" src="../../images/projects/jack-rabbit/cover.jpg" alt="JackRabbit Bike Hero" aspectRatio={16/7} layout={"fullWidth"}/>


      <div class="columns-1 lg:columns-2">
        <StaticImage class="mb-6" src="../../images/projects/jack-rabbit/browser.jpg" alt="JackRabbit Bike Browser Preview" layout={"fullWidth"} aspectRatio={4/5}/>
        <StaticImage class="mb-6" src="../../images/projects/jack-rabbit/home--macbook.jpg" alt="JackRabbit Bike Home Reviews" layout={"fullWidth"} aspectRatio={4/5}/>
      </div>
      <div class="columns-1 lg:columns-2">
        <StaticImage class="mb-6" src="../../images/projects/jack-rabbit/mobile--01.jpg" alt="JackRabbit Bike Browser Preview" layout={"fullWidth"} aspectRatio={4/5}/>
        <StaticImage class="mb-6" src="../../images/projects/jack-rabbit/mobile--02.jpg" alt="JackRabbit Bike Home Preview" layout={"fullWidth"} aspectRatio={4/5}/>
      </div>
      <StaticImage class="mb-20" src="../../images/projects/jack-rabbit/home--desktop.jpg" alt="JackRabbit Bike Home Preview" layout={"fullWidth"}/>

      <div class="adv-gallery-v3 mb-20">
        <h4 className="color-contrast-higher mb-4 pl-8 lg:pl-0">Related</h4>
        <ul class="columns-1 lg:columns-2">
          <ProjectCard classes="" title="Auris" url="auris"  description="The sound of the past, built for the future." cover={Auris}  role="Design + Development + Branding"/>

          <ProjectCard classes="" title="GoSun" url="gosun" description="Creating a winning brand, design system, and e-commerce experience to disrupt the outdoors market. Raised $2m+ via crowdfunding and secured Series A funding in first 8 months." cover={GoSun}  role="Design + Development + Branding"/>
        </ul>
      </div>

  </Layout>
)

export default aurisPage
